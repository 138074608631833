.customerNav {
    padding: 5pt 10pt;
    display: flex;
	justify-content: flex-start;
    align-items: center;
    background-color: purple;
    color: wheat;
}

body{
    margin: 0;
    padding: 0;
    background-color: #131B3D;
    user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
	-webkit-appearance: none !important;
	margin: 0;
}

@font-face {
    font-family: 'Aacad';
    src: url(../public/Afacad-VariableFont_wght.ttf)
  }

  .hideScrollBar {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overflow-y: auto;
  }